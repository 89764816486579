import styled, { css } from "styled-components";
import { H2, H4, H5, TEXT_BODY_2 } from "../../../assets/styles/typography";

import MobileLogoImg from "-!svg-react-loader!../../../assets/images/Logo/mobileLogo.svg";
import { fadeIn, fadeOut } from "../../../utils/animations";
import { device } from "../../../utils/breakpoints";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #151515;
  background: #ffffff;
`;

export const MobileNavigationBar = styled.div`
  height: 72px;
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  overflow: hidden;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 16px 4px rgba(29, 29, 27, 0.04);
`;

export const NavigationBar = styled.div`
  height: 72px;
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  overflow: hidden;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 16px 4px rgba(29, 29, 27, 0.04);

  @media ${device.tablet}, ${device.tabletLandscape}, ${device.computer} {
    display: none;
  } ;
`;

export const LogoMobile = styled(MobileLogoImg)`
  width: 110px;
  height: 24px;

  @media ${device.tablet}, ${device.tabletLandscape}, ${device.computer} {
    display: none;
  }
`;

export const DesktopNavigationWrapper = styled.div`
  display: none;

  @media ${device.tablet}, ${device.tabletLandscape}, ${device.computer} {
    display: block;
  }
`;

export const BackArrowWrapper = styled.div`
  margin: 112px 24px 0;
  transition: 0.3s;

  svg {
    height: 36px;
    width: 54px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.24;
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin: 88px 64px 0 88px;

    svg {
      height: auto;
    }
  }

  @media ${device.computer} {
    margin: 80px 120px 0 120px;

    svg {
      height: auto;
    }
  }

  @media ${device.desktop} {
    margin: 128px 120px 0 120px;
  } ;
`;

export const Title = styled.div`
  ${H4}
  padding: 64px 24px 0;
  margin: 0;
  color: #013cff;

  @media ${device.tablet}, ${device.tabletLandscape} {
    font-size: 48px;
    line-height: 64px;
    padding: 80px 0 0 88px;
  }

  @media ${device.computer} {
    ${H2}
    padding: 80px 64px 0 120px;
  }

  @media ${device.desktop} {
    padding: 128px 120px 0 120px;
  } ;
`;

export const Subtitle = styled(Title)`
  padding-top: 0 !important;
  font-weight: 400;
`;

export const SectionWrapper = styled.div`
  width: 80%;

  @media ${device.mobile} {
    width: 100%;
  }

  @media ${device.computer} {
    width: 65%;
  } ;
`;

export const SectionTitle = styled.h2`
  ${H5}
  padding: 24px 24px 0;
  margin: 0;
  color: #151515;

  @media ${device.tablet}, ${device.tabletLandscape} {
    font-size: 28px;
    line-height: 36px;
    padding: 52px 32px 0 88px;
  }

  @media ${device.computer} {
    ${H4}
    padding: 80px 0 0 120px;
  } ;
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  padding: 0 24px;
  margin: 0;
  white-space: pre-line;
  color: #151515;

  h6 {
    line-height: 18px;
    font-weight: 400;
  }

  a {
    color: #013cff;
    font-weight: 600;
    text-decoration: none;
  }

  *:not(h6) {
    font-size: inherit;
    line-height: inherit;
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    padding-left: 88px;
    padding-right: 0;
  }

  @media ${device.computer} {
    ${TEXT_BODY_2}
    padding-left: 120px;
    padding-right: 0;
    margin: 0;
  } ;
`;

export const HorizontalDivider = styled.div`
  width: 80px;
  height: 5px;
  margin: 32px 0 24px;
  background: #013cff;

  @media ${device.tablet}, ${device.tabletLandscape} {
    width: 144px;
    margin: 24px 88px 32px 88px;
    min-height: 8px;
  }

  @media ${device.computer} {
    width: 112px;
    height: 6px;
    margin: 24px 0 40px 120px;
  } ;
`;

export const BackgroundWrapper = styled.div`
  position: absolute;
  width: 32%;
  height: 58%;
  top: 0;
  right: 0;
  display: grid;
  grid-template-columns: 14% 42% 12% 32%;
  grid-template-rows: 10% 16% 16% 16% 32% 10%;

  @media ${device.tablet} {
    width: 44%;
    height: 32%;
  }

  @media ${device.tabletLandscape} {
    width: 36%;
    height: 50%;
  }

  @media ${device.computer}, ${device.desktop} {
    width: 32%;
    height: 58%;
  } ;
`;

interface BlueReactangleProps {
  column?: string;
  row?: string;
}

export const BlueRectangle = styled.div`
  width: 100%;
  height: 100%;
  margin-left: auto;
  grid-column: ${(props: BlueReactangleProps) => props.column || "auto / auto"};
  grid-row: ${(props: BlueReactangleProps) => props.row || "auto / auto"};
  background-color: #013cff;
`;

export const FooterWrapper = styled.div`
  margin: 0 0 48px 24px;
  display: flex;
  flex-direction: row;

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin-left: 88px;
  }

  @media ${device.computer} {
    margin: 0 0 80px 120px;
  }

  @media ${device.desktop} {
    margin: 0 0 120px 120px;
  } ;
`;

export const BackToTopArrowWrapper = styled.div`
  min-height: 68px;
  margin: 24px 48px 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  svg {
    cursor: pointer;
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin: 32px 51px 0 0;
  }

  @media ${device.computer} {
    margin: 32px 120px 0 0;
  }

  @media ${device.desktop} {
    margin: 50px 120px 0 0;
  } ;
`;

export const ContactInformation = styled.div`
  width: auto;
  height: 100%;
  margin: 0;

  &:first-of-type {
    margin-right: 4vw;
  }
`;

export const ContactTitle = styled.h2`
  margin: 0;
  font-size: 0.8rem;
  font-weight: 600;
  color: #151515;
`;

export const ContactDescription = styled.p`
  margin: 0;
  font-size: 0.8rem;
  color: #151515;
  white-space: pre-line;
`;

interface PageProps {
  animation?: string;
}

export const TransitionWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-template-rows: repeat(4, auto);
  z-index: 1000;
  pointer-events: none;

  & > * {
    animation-name: ${(props: PageProps) =>
      props.animation
        ? css`
            ${fadeIn}
          `
        : css`
            ${fadeOut}
          `};
    animation-timing-function: linear;
  }
`;

interface TransitionBlockProps {
  delay: number;
}

export const TransitionRandomBlock = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #ffffff;
  z-index: 1;
  opacity: 0;
  animation-duration: ${(props: TransitionBlockProps) => `${props.delay}ms`};
  animation-fill-mode: forwards;
`;
